














































































































//@ts-nocheck
import Vue from 'vue'
import DataTable from '@/components/dataTable/DataTable.vue'
import { IDataTable } from '@/types/table'
import ColorSwatches from '@/mixins/colorSwatches'
import apiClient from '@/services/danaApi'
import { IMachine, IMachineDetail, IMachineDetailFilter, ISummary } from '@/types/machines'
import { IDateTimePicker } from '@/types/dateTimePicker'
import qs from 'qs'
import { formatCurrencyWithSpaces, formatDate } from '@/utils/helpers'
import { dateColorSwatch } from '@/utils/colorSwatches'
import moment from 'moment-timezone'

export default Vue.extend({
	name: 'MachineDetail',
	components: { DataTable },
	mixins: [ColorSwatches],
	data() {
		return {
			tableProps: {
				headers: [
					{
						text: 'Datetime',
						value: 'datetime',
						type: 'dateTime',
					},
					{
						text: 'In',
						value: 'IN',
						type: 'currency',
						append: process.env.VUE_APP_CURRENCY,
						sum: true,
						sumValue: 'IN',
					},
					{
						text: 'Out',
						value: 'OUT',
						type: 'currency',
						append: process.env.VUE_APP_CURRENCY,
						sum: true,
						sumValue: 'OUT',
					},
				],
				filters: [
					{
						source: 'dateTime',
						type: 'DateTimePicker',
						attributes: {} as IDateTimePicker,
						//TODO need debugging not working properly with more than one default value
						defaultValue: [],
						alwaysOn: true,
					},
					{
						source: 'order',
						type: 'SelectInput',
						label: 'Order',
						choices: [
							{
								value: 'IN_desc',
								text: 'IN descending',
							},
							{
								value: 'IN_asc',
								text: 'IN ascending',
							},
							{
								value: 'OUT_desc',
								text: 'OUT descending',
							},
							{
								value: 'OUT_asc',
								text: 'OUT ascending',
							},
						],
						attributes: {
							smallChips: true,
						},
					},
				],
			} as IDataTable,
			machineDetail: {} as IMachineDetail,
			machineDetailLoading: false,
			summary: {} as ISummary,
			summaryLoading: false,
			machineInfo: {} as IMachine,
			currency: process.env.VUE_APP_CURRENCY as string,
		}
	},
	computed: {
		lastOnlineSwatch(): string {
			const date = new Date(moment(Date.now()).tz(process.env.VUE_APP_TIMEZONE).format())

			const success = moment(new Date(new Date(date).setMinutes(date.getMinutes() - 10)).toISOString())
				.format()
				.substr(0, 19)

			const warning = moment(new Date(new Date(date).setHours(date.getHours() - 1)).toISOString())
				.format()
				.substr(0, 19)

			return dateColorSwatch(this.machineInfo.board.mind.last_online, success, warning, true)
		},
		lastSyncSwatch(): string {
			const date = new Date(moment(Date.now()).tz(process.env.VUE_APP_TIMEZONE).format())

			const success = moment(new Date(new Date(date).setMinutes(date.getMinutes() - 10)).toISOString())
				.format()
				.substr(0, 19)

			const warning = moment(new Date(new Date(date).setHours(date.getHours() - 1)).toISOString())
				.format()
				.substr(0, 19)

			return dateColorSwatch(this.machineInfo.board.mind.last_sync, success, warning, true)
		},
	},
	methods: {
		formatCurrency: formatCurrencyWithSpaces,
		setDefaultDate(): void {
			let sinceDate = (): string => {
				const date = new Date(moment(Date.now()).tz(process.env.VUE_APP_TIMEZONE).format())

				return moment(new Date(new Date(date).setMonth(date.getMonth() - 1)).toISOString())
					.format()
					.substr(0, 10)
			}

			let toDate = (): string => {
				const date = new Date(moment(Date.now()).tz(process.env.VUE_APP_TIMEZONE).format())

				return moment(date.toISOString()).format().substr(0, 10)
			}

			this.tableProps.filters[0].defaultValue.push(sinceDate())
			this.tableProps.filters[0].defaultValue.push(toDate())
		},
		async getMachineDetail(id: number, options?: never) {
			this.machineDetailLoading = true

			let sort = []
			let sortOrder = []

			if (options.filters.order) {
				const sortIn = options.filters.order.split('_')[0]
				sort.push(sortIn)
				const sortInOrder = options.filters.order.split('_')[1]
				sortOrder.push(sortInOrder)
			}

			const rawParams = qs.stringify(
				{
					machine_id: id,
					limit: options.pagination.itemsPerPage,
					offset: (options.pagination.page - 1) * options.pagination.itemsPerPage,
					//TODO possible Error with array access
					since_date: options.filters.dateTime[0] ?? null,
					to_date: options.filters.dateTime[1] ?? null,
					sort_columns: sort,
					sort_orders: sortOrder,
				},
				{
					arrayFormat: 'repeat',
					indices: false,
				},
			)

			const params = new URLSearchParams(rawParams)

			await apiClient
				.get('/stats/bookkeepings', {
					params,
				})
				.then((response) => {
					this.machineDetailLoading = false
					this.machineDetail = response.data.result
				})
		},
		async getSummary(id: number, options?: never) {
			this.summaryLoading = true

			const rawParams = qs.stringify(
				{
					machine_id: id,
					since_date: options.filters.dateTime[0] ?? null,
					to_date: options.filters.dateTime[1] ?? null,
				},
				{
					arrayFormat: 'repeat',
					indices: false,
				},
			)

			const params = new URLSearchParams(rawParams)

			await apiClient
				.get('/stats/summary', {
					params,
				})
				.then((response) => {
					this.summaryLoading = false
					this.summary = response.data.result
				})
		},
		async getMachine(id: number) {
			await apiClient
				.get('/info/machines', {
					params: {
						machine_id: id,
					},
				})
				.then((response) => {
					const machine = response.data.result.machines

					for (let i of machine) {
						this.machineInfo = i
					}
				})
		},
		fetchMachineDetail(event: IMachineDetailFilter) {
			this.getSummary(parseInt(this.$route.params.id, 10), event)
			this.getMachineDetail(parseInt(this.$route.params.id, 10), event)
		},
		formatDate: formatDate,
		dateColorSwatch: dateColorSwatch,
	},
	created() {
		this.getMachine(parseInt(this.$route.params.id, 10))
		this.setDefaultDate()
	},
})
