var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-2",attrs:{"elevation":"10"}},[_c('v-card-title',{staticClass:"pb-0"},[_vm._v(" Machine Detail - "+_vm._s(_vm.machineInfo.sign ? _vm.machineInfo.sign.sn.sn : '')+" "),_c('v-spacer'),_vm._v(" Room: "+_vm._s(_vm.machineInfo.location ? _vm.machineInfo.location.name : '')+" ")],1),_c('v-card-text',[_vm._v(" last online: "),(_vm.machineInfo.board)?_c('span',{class:_vm.lastOnlineSwatch},[_vm._v(_vm._s(_vm.formatDate(_vm.machineInfo.board.mind.last_online, 'YYYY/MM/DD HH:mm:ss')))]):_vm._e(),_c('br'),_vm._v(" last sync: "),(_vm.machineInfo.board)?_c('span',{class:_vm.lastSyncSwatch},[_vm._v(_vm._s(_vm.formatDate(_vm.machineInfo.board.mind.last_sync, 'YYYY/MM/DD HH:mm:ss')))]):_vm._e()]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-card',{attrs:{"elevation":"3","loading":_vm.summaryLoading}},[_c('v-card-text',[_c('div',{staticClass:"text-h6 font-weight-bold"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fa-money-bill-wave")]),_c('span',[_vm._v("NETTO: ")]),(_vm.summary.netto)?_c('span',{class:_vm.singleValueColorSwatch(_vm.summary.netto)},[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.summary.netto))+" "+_vm._s(_vm.currency)+" ")]):_vm._e()],1)])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-card',{attrs:{"elevation":"3","loading":_vm.summaryLoading}},[_c('v-card-text',[_c('div',{staticClass:"text-h6 font-weight-bold"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fa-trophy")]),_c('span',[_vm._v("RTP: ")]),(_vm.summary.rtp !== null && _vm.summary)?_c('span',{class:_vm.rangeColorSwatch(
											_vm.summary.rtp,
											[[92, 95]],
											[
												[87, 91],
												[96, 100] ]
										)},[_vm._v(_vm._s(_vm.summary.rtp)+"%")]):_c('span',[_vm._v(" No data available ")])],1)])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-card',{attrs:{"elevation":"3","loading":_vm.summaryLoading}},[_c('v-card-text',[_c('div',{staticClass:"text-h6 font-weight-bold"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fa-receipt")]),_c('span',[_vm._v("Payout: ")]),(_vm.summary.payroll !== null && _vm.summary)?_c('span',{class:_vm.rangeColorSwatch(
											_vm.summary.payroll,
											[[70, 80]],
											[
												[50, 69],
												[81, 100] ]
										)},[_vm._v(_vm._s(_vm.summary.payroll)+"%")]):_c('span',[_vm._v(" No data available ")])],1)])],1)],1)],1)],1),_c('v-card-text',[_c('DataTable',{attrs:{"items":_vm.machineDetail.bookkeepings,"headers":_vm.tableProps.headers,"server-items-length":_vm.machineDetail.count,"sum":_vm.machineDetail.sum,"sort":_vm.tableProps.sort,"sort-by":_vm.tableProps.sortBy,"filters":_vm.tableProps.filters,"loading":_vm.machineDetailLoading,"date-time-format":"YYYY/MM/DD HH:mm:ss","height":"100%","items-per-page":-1,"iterator":""},on:{"fetchData":function($event){return _vm.fetchMachineDetail($event)}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }